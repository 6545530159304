import React from "react"
import { graphql } from "gatsby"
import { MDXProvider } from "@mdx-js/react"
import { MDXRenderer } from "gatsby-plugin-mdx"
import { Link } from "gatsby"
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Layout from '../../components/layout'
import { StaticImage } from 'gatsby-plugin-image'
import Button from 'react-bootstrap/Button'

const MissionOverviewPage = () => {
  return (
    <Layout pageTitle="Mission Overview">
      <h1>INCUS Mission Overview</h1>
      <h4>
        The goal of <strong>INCUS</strong> is to understand why, when and where tropical convective storms form, and why only some storms produce extreme weather.
      </h4>
      <div className="d-flex justify-content-center">
        <StaticImage
          alt="a diagram showing three radar slices of convection from space"
          src="../../images/incus_thin_diagram.png"
          layout="constrained"
          placeholder="blurred"
        />
      </div>
      <br />
      <p>
        The primary goals of the INvestigation of Convective Updrafts (INCUS) mission are to determine:
        <ol>
          <li>The predominant environmental properties controlling convective mass flux   in tropical convective storms</li>
          <li>The relationship between convective mass flux and high anvil clouds</li>
          <li>The relationship between convective mass flux and the type and intensity of the extreme weather produced</li>
        </ol>
        Using the relationships between convective mass flux and environmental factors, high anvil clouds, and extreme weather, the numerical models that predict weather and climate will then be evaluated using these data.
      </p>
      <Row>
        <h2>Science</h2>

        <Col md={8}>

          Life on Earth is fundamentally linked to convective storms, from the fresh water they supply to the extreme weather they generate. The frequency of extreme weather has increased over the last 40 years, and is expected to continue increasing as the climate warms. In spite of the fundamental role of convective storms, our current understanding of storm processes is rudimentary, constrained in part due to our limited observations. INCUS will begin to address this, providing the first tropics-wide investigation of the evolution of the vertical transport of air and water by convective storms, one of the most influential, yet unmeasured atmospheric processes. More information on INCUS science can be found on the Science Goals and Objectives  page.         </Col>
        <Col md={4} className="d-flex justify-content-center align-self-center">
          <StaticImage
            alt="Diagram showing convective mass flux in a convective storm cloud"
            src="../../images/cmf_image.png"
            layout="constrained"
            placeholder="blurred"
            aspectRatio={16 / 9}

          />
        </Col>

      </Row>
      <Row>
        <h2>Instruments</h2>

        <Col md={8} className="d-flex justify-content-center align-self-center">

          <p>
            INCUS is a series of three SmallSats  in close succession each carrying a RainCube  -like Ka-band radar and one with a TEMPEST  -D-like radiometer with cross-track scanning capabilities. A novel time-differencing approach between the three satellites flown in close succession (30, and 90, and 120 seconds apart) will provide the first estimates of convective mass flux across the tropics. More information on the instrumentation can be found on the Instruments page.           </p>
        </Col>
        <Col md={4} className="d-flex justify-content-center align-self-center">
          <StaticImage
            alt="Illustration showing the three satellites that comprise INCUS orbiting the earth"
            src="../../images/incus_diagram.png"
            layout="constrained"
            placeholder="blurred"
            aspectRatio={16 / 9}

          />
        </Col>
      </Row>
      <h2>Relevance</h2>
      <p>
        The INCUS mission directly addresses the critical question “Why do convective storms, heavy precipitation, and clouds occur exactly when and where they do? (W-4)” from the <a href="https://www.nationalacademies.org/our-work-decadal-survey-for-earth-science-and-applications-from-space" target="_blank">National Academies of Science and Engineering 2017-2027 Decadal Survey</a>.
      </p>

      <h2>Scientific Overview Video</h2>
      <p>
        Watch Principal Investigator Susan van den Heever describe the INCUS mission and its scientific goals.
      </p>

      <a href="https://drive.google.com/file/d/1FFq82su121axUQK2eIlmQs1Sg4jmg-sq/view?usp=share_link" target="_blank">
        <Button>Watch the Overview Video</Button>
      </a>


    </Layout>

  )
}

export default MissionOverviewPage

